import * as React from 'react';

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={220}
      height={199}
      viewBox="0 0 220 199"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <title>{'BF22E7CB-8DF3-4399-B3A6-B5F5D56D497B'}</title>
      <defs>
        <linearGradient x1="50.014%" y1="99.989%" x2="50.014%" y2="-.002%" id="prefix__a">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50.006%" x2="100.009%" y2="50.006%" id="prefix__b">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="99.991%" y2="50%" id="prefix__c">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1=".009%" y1="50%" x2="100%" y2="50%" id="prefix__d">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1=".108%" y1="50.022%" x2="100.108%" y2="50.022%" id="prefix__e">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100.024%" y2="50%" id="prefix__f">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="prefix__g">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50.003%" x2="100%" y2="50.003%" id="prefix__h">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100%" y2="50%" id="prefix__i">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1=".017%" y1="50.058%" x2="100.002%" y2="50.058%" id="prefix__j">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100.099%" y2="50%" id="prefix__k">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="-.099%" y1="50%" x2="100%" y2="50%" id="prefix__l">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100.036%" y2="50%" id="prefix__m">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="49.993%" x2="99.991%" y2="49.993%" id="prefix__n">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="50%" x2="100.008%" y2="50%" id="prefix__o">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="49.989%" x2="100%" y2="49.989%" id="prefix__p">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="49.975%" x2="99.864%" y2="49.975%" id="prefix__q">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="0%" y1="49.975%" x2="99.864%" y2="49.975%" id="prefix__r">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100.125%" x2="50%" y2="0%" id="prefix__s">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="0%" id="prefix__t">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="-.125%" id="prefix__u">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50.007%" y1="100%" x2="50.007%" y2="0%" id="prefix__v">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50.002%" y1="100.002%" x2="50.002%" y2="-.01%" id="prefix__w">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="49.936%" y1="100.023%" x2="49.936%" y2="-.069%" id="prefix__x">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="49.992%" y1="100%" x2="49.992%" y2="0%" id="prefix__A">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <linearGradient x1="50%" y1="100%" x2="50%" y2="-.037%" id="prefix__B">
          <stop stopOpacity={0.12} offset="0%" />
          <stop stopOpacity={0.09} offset="55%" />
          <stop stopOpacity={0.02} offset="100%" />
        </linearGradient>
        <linearGradient x1="49.996%" y1="100.001%" x2="49.996%" y2=".007%" id="prefix__C">
          <stop stopColor="gray" stopOpacity={0.25} offset="0%" />
          <stop stopColor="gray" stopOpacity={0.12} offset="54%" />
          <stop stopColor="gray" stopOpacity={0.1} offset="100%" />
        </linearGradient>
        <path d="M7.228 1.15L6.75.015.525 2.573a.768.768 0 00-.478.71V10h1.23V3.59l5.95-2.44z" id="prefix__y" />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M19.43 87.823h180.535a6.525 6.525 0 016.525 6.525v64.765H12.905V94.347a6.525 6.525 0 016.525-6.524z"
          fill="#535461"
          fillRule="nonzero"
        />
        <path
          d="M4.928 123.467h15.957a3.083 3.083 0 013.082 3.083v32.563H3.844a2 2 0 01-2-2V126.55a3.083 3.083 0 013.085-3.083z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M3.083 123.467H19.04a3.083 3.083 0 013.085 3.083v32.563H2a2 2 0 01-2-2V126.55a3.083 3.083 0 013.083-3.083z"
          fill="#535461"
          fillRule="nonzero"
        />
        <path
          d="M215.55 159.112h-20.125v-32.56a3.082 3.082 0 013.083-3.082h15.96a3.082 3.082 0 013.082 3.082v30.56a2 2 0 01-2 2z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M217.395 159.112H197.27v-32.56a3.082 3.082 0 013.083-3.082h15.957a3.082 3.082 0 013.083 3.082v30.56a2 2 0 01-2 2h.002z"
          fill="#535461"
          fillRule="nonzero"
        />
        <path
          d="M26.835 134.838H192.56a4.71 4.71 0 014.71 4.71v19.565H22.125v-19.565a4.71 4.71 0 014.71-4.71z"
          fill="#535461"
          fillRule="nonzero"
        />
        <path
          d="M26.835 134.838H192.56a4.71 4.71 0 014.71 4.71v19.565H22.125v-19.565a4.71 4.71 0 014.71-4.71z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.15}
        />
        <path fill="#535461" fillRule="nonzero" d="M9.525 159.113h6.76v7.067h-6.76z" />
        <path fill="#000" fillRule="nonzero" opacity={0.15} d="M9.525 159.113h6.76v7.067h-6.76z" />
        <path fill="#000" fillRule="nonzero" opacity={0.1} d="M9.525 159.113h6.76v1h-6.76z" />
        <path fill="#535461" fillRule="nonzero" d="M209.868 166.177h-6.76v-7.067h6.76z" />
        <path fill="#000" fillRule="nonzero" opacity={0.15} d="M209.868 166.177h-6.76v-7.067h6.76z" />
        <path fill="#000" fillRule="nonzero" opacity={0.1} d="M209.868 159.417h-6.76v-1h6.76z" />
        <text fill="#000" fillRule="nonzero" opacity={0.7} fontFamily="Lato-Regular, Lato" fontSize={9.58}>
          <tspan x={51.07} y={113.463}>
            {'1'}
          </tspan>
        </text>
        <path
          d="M38.447 77.162l-1-.187 1.988-7.328c3.13-23.482-8.698-25.847-13.133-25.927-.51-.186-1.03-.344-1.557-.472v-2.77a14.703 14.703 0 0010.952-11.873h.038c.705 0 1.278-1.073 1.278-2.395a3.325 3.325 0 00-.555-1.975 15.28 15.28 0 00-4.648-12.6 9.85 9.85 0 00-1.085-2.918c-1.088-1.917-2.9-3.917-2.163-6l-4.782.5-.045-3.15c-1.5.268-2.117 2-3 3.23-2.13 2.963-6.402 3.376-9.23 5.683-.96.785-3.123 3.17-2.713 4.735a15.25 15.25 0 00-2.917 9c0 .518.026 1.03.077 1.538a3.325 3.325 0 00-.554 1.974c0 1.323.572 2.395 1.277 2.395h.038a14.702 14.702 0 0010.985 11.855v2.773c-.528.128-1.048.286-1.558.472-4.435.08-16.262 2.445-13.133 25.928l1.988 7.328-.473.087a5.45 5.45 0 00-4.357 6.358l7.84 42a1.65 1.65 0 001.925 1.32l1.383-.25v.817a4.588 4.588 0 00-2.556 4.11v.52h9.25v-.52c0-1.614-.849-3.108-2.235-3.935v-1.828l1.263-.234a1.65 1.65 0 001.32-1.925L11 84.02h20.427l-7.364 39.463a1.65 1.65 0 001.32 1.924l1.262.236v1.827a4.585 4.585 0 00-2.235 3.935v.52h9.25v-.52a4.588 4.588 0 00-2.555-4.11v-.817l1.382.25a1.65 1.65 0 001.925-1.32l7.94-42.533a4.905 4.905 0 00-3.904-5.713z"
          opacity={0.7}
          transform="translate(32.25 51.5)"
          fill="url(#prefix__a)"
          fillRule="nonzero"
        />
        <path
          fill="#FDB797"
          fillRule="nonzero"
          d="M43.94 172.632h4.303v5.838H43.94zM58.69 172.632h4.303v5.838H58.69z"
        />
        <path fill="#4D8AF0" fillRule="nonzero" d="M39.33 124.698H67.6v10.14H39.33z" />
        <path d="M39.33 124.698H67.6v10.14H39.33z" opacity={0.05} fill="#000" fillRule="nonzero" />
        <path
          d="M39.333 126.54S49.88 129 53.465 129c3.585 0 14.135-2.457 14.135-2.457V125H39.333v1.54z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M67.6 126.233s-10.547 2.457-14.135 2.457c-3.588 0-14.135-2.457-14.135-2.457v-16.9a14.135 14.135 0 0114.135-14.135c7.807 0 14.135 6.328 14.135 14.135v16.9z"
          fill="#FC5656"
          fillRule="nonzero"
        />
        <path
          d="M50.085 88.13h6.75v8.313a2.75 2.75 0 01-2.75 2.75h-1.25a2.75 2.75 0 01-2.75-2.75V88.13z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          d="M49.748 174.605L42.32 176a1.292 1.292 0 01-1.5-1.032L33.038 133.3a4.265 4.265 0 013.41-4.978l4.5-.84a1.292 1.292 0 011.5 1.033l8.322 44.583a1.293 1.293 0 01-1.023 1.507zM57.185 174.605L64.612 176a1.292 1.292 0 001.5-1.032l7.86-42.088a3.84 3.84 0 00-3.07-4.477l-4.924-.92a1.292 1.292 0 00-1.5 1.032l-8.325 44.583a1.293 1.293 0 001.032 1.507z"
          fill="#4D8AF0"
          fillRule="nonzero"
        />
        <path
          d="M50.085 93.25c2.217.545 4.533.545 6.75 0v-4.813h-6.75v4.813z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <circle fill="#333" fillRule="nonzero" cx={53.465} cy={75.84} r={14.75} />
        <circle fill="#FDB797" fillRule="nonzero" cx={53.465} cy={79.22} r={14.135} />
        <path
          d="M44.133 62.635c2.72-2.22 6.832-2.615 8.88-5.465.852-1.185 1.452-2.848 2.89-3.108l.042 3.033 4.602-.472c-.71 2 1.033 3.922 2.08 5.77a9.5 9.5 0 011.228 4.465c-1.597.96-3.577-.088-5.357-.645-1.728-.543-3.568-.598-5.378-.65a11.175 11.175 0 00-2.775.152 14 14 0 00-2.29.843c-1.225.5-5.087 2.447-6.225 1.2-1.292-1.418 1.255-4.258 2.303-5.123z"
          fill="#333"
          fillRule="nonzero"
        />
        <path
          d="M44.903 72.132a6.483 6.483 0 002.5.75c3.62.495 7.307.081 10.727-1.205.56 1.42 1.927 2.395 3.38 2.858 1.453.463 3 .487 4.53.5.336.037.676-.028.975-.188.68-.46.35-1.5-.045-2.227L65 69.05c-.582-1.055-1.18-2.13-2.083-2.93-1.13-1-2.63-1.468-4.11-1.765a23.175 23.175 0 00-10.547.352c-3.677 1.006-8.405 4.37-3.357 7.425z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M44.903 71.825a6.483 6.483 0 002.5.75c3.62.495 7.307.08 10.727-1.205.56 1.42 1.927 2.395 3.38 2.858 1.453.462 3 .487 4.53.5.336.037.676-.029.975-.188.68-.46.35-1.5-.045-2.228l-1.968-3.562c-.582-1.055-1.18-2.13-2.082-2.93-1.13-1-2.63-1.467-4.11-1.765a23.175 23.175 0 00-10.547.352c-3.68.998-8.408 4.363-3.36 7.418z"
          fill="#333"
          fillRule="nonzero"
        />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={67.448} cy={79.22} rx={1.23} ry={2.305} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={39.485} cy={79.22} rx={1.23} ry={2.305} />
        <path
          d="M50.142 96.14S32.266 93.355 35.947 121l2.15 7.928 6.148-.25s-4-9.218-1.537-15.365c2.462-6.148 7.434-17.173 7.434-17.173zM56.79 96.14s17.88-2.785 14.193 24.87l-2.15 7.927-6.148-.25s4-9.217 1.537-15.365C61.76 107.175 56.79 96.14 56.79 96.14z"
          fill="#FC5656"
          fillRule="nonzero"
        />
        <circle fill="#FDB797" fillRule="nonzero" cx={41.483} cy={129.92} r={3.38} />
        <circle fill="#FDB797" fillRule="nonzero" cx={66.065} cy={129.92} r={3.38} />
        <path
          d="M45.075 176h1.722a3.592 3.592 0 013.593 3.593v1.322h-8.907v-1.322A3.592 3.592 0 0145.075 176zM60.133 176h1.722a3.593 3.593 0 013.593 3.593v1.322h-8.91v-1.322A3.592 3.592 0 0160.133 176z"
          fill="#333"
          fillRule="nonzero"
        />
        <path
          d="M55.063 105c.75 0 1.453.074 2.109.223.656.148 1.226.386 1.71.715.485.328.868.757 1.15 1.289.28.53.421 1.18.421 1.945 0 1.11-.195 2.05-.586 2.824a5.234 5.234 0 01-1.57 1.887 6.465 6.465 0 01-2.285 1.055c-.867.218-1.778.328-2.73.328h-1.548l-.683 3.45L47 120.234l1.238-6.182c2.576.123 4.517-.13 5.821-.762 1.36-.659 2.272-1.809 2.736-3.45l-.036.116-.029.097.032-.097.029-.103.003-.013a2.59 2.59 0 00.073-.621c0-1.399-1.023-2.285-2.285-2.285a2.285 2.285 0 00-.898 4.386c.246.246.03.635-.647 1.168-.679.535-2.39 1.05-5.135 1.546l.351-.066L50.047 105z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M42.405 110.87s-1.23 6.453.922 9.218M64.528 110.87s1.23 6.453-.923 9.218"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <g opacity={0.7} transform="translate(76.75 41.75)" fillRule="nonzero">
          <path fill="url(#prefix__b)" d="M27.915 69.16h29.113v21.517H27.915z" />
          <path
            d="M.168 5.607S11.03 6.872 14.725 6.872c3.695 0 14.557-1.264 14.557-1.264V.228H.168v5.38z"
            opacity={0.1}
            transform="translate(27.75 69.25)"
            fill="url(#prefix__c)"
          />
          <path
            d="M57.03 74.54s-10.863 1.265-14.558 1.265c-3.694 0-14.557-1.265-14.557-1.265V53.337c0-8.04 6.518-14.557 14.557-14.557 8.04 0 14.558 6.518 14.558 14.557V74.54z"
            fill="url(#prefix__d)"
          />
          <path
            d="M43.197 42.895h-1.462a2.75 2.75 0 01-2.75-2.75V31.5h6.963v8.642a2.75 2.75 0 01-2.75 2.753z"
            fill="url(#prefix__e)"
          />
          <path fill="url(#prefix__f)" d="M27.915 83.085h10.443v70.885H27.915z" />
          <path fill="url(#prefix__g)" d="M46.587 83.085H57.03v70.885H46.587z" />
          <path
            d="M.242 5.027c2.287.563 4.676.563 6.963 0V.07H.242v4.957z"
            opacity={0.1}
            transform="translate(38.75 31.75)"
            fill="url(#prefix__h)"
          />
          <circle fill="url(#prefix__i)" cx={42.472} cy={18.845} r={15.19} />
          <circle fill="url(#prefix__i)" cx={42.472} cy={22.325} r={14.557} />
          <path
            d="M2.402 8.527c.773.468 1.68.653 2.575.776 3.73.506 7.526.076 11.048-1.25.578 1.462 1.985 2.467 3.483 2.942 1.497.475 3.095.5 4.667.52.345.038.694-.03 1-.193.7-.472.363-1.554-.047-2.294L23.1 5.35c-.6-1.085-1.215-2.195-2.145-3.017-1.162-1.03-2.707-1.5-4.232-1.818A23.865 23.865 0 005.86.877c-3.785 1.038-8.663 4.506-3.458 7.65z"
            opacity={0.1}
            transform="translate(31.25 6.5)"
            fill="url(#prefix__j)"
          />
          <path
            d="M33.653 14.71c.772.467 1.68.652 2.575.775 3.729.506 7.525.077 11.047-1.25.578 1.463 1.985 2.468 3.483 2.942 1.497.476 3.094.5 4.667.52.345.038.694-.029 1-.192.7-.473.363-1.555-.047-2.295l-2.028-3.678c-.6-1.085-1.215-2.194-2.145-3.017-1.163-1.03-2.707-1.5-4.233-1.818a23.865 23.865 0 00-10.862.363c-3.785 1.038-8.662 4.505-3.457 7.65z"
            fill="url(#prefix__j)"
          />
          <ellipse fill="url(#prefix__k)" cx={56.87} cy={22.325} rx={1.265} ry={2.373} />
          <ellipse fill="url(#prefix__l)" cx={28.075} cy={22.325} rx={1.265} ry={2.373} />
          <circle fill="url(#prefix__m)" cx={81.395} cy={3.655} r={3.48} />
          <text fill="#000" fontFamily="Lato-Regular, Lato" fontSize={9.492}>
            <tspan x={36.968} y={57.042}>
              {'00'}
            </tspan>
          </text>
          <path
            d="M42.79 28.023s-8.227 2.847-14.557-3.48c0 0 .5 2.097 1.392 4.627a14.557 14.557 0 0027.22-4.525c-1.483.82-12.295 6.018-14.055 3.378zm-.318 3.797c-1.572 0-2.847-1.2-2.847-1.898 0-.697 1.275-.632 2.847-.632 1.573 0 2.848-.065 2.848.633 0 .697-1.275 1.897-2.848 1.897z"
            fill="url(#prefix__n)"
          />
          <path
            d="M50.21 41s16.945-9.5 27.387-37.355L82.66 6.81S74.275 42.305 53.233 50.5L50.21 41z"
            fill="url(#prefix__o)"
          />
          <circle fill="url(#prefix__i)" cx={3.55} cy={3.655} r={3.48} />
          <path
            d="M34.735 41S17.79 31.5 7.348 3.655L2.285 6.82s8.385 35.485 29.43 43.68l3.02-9.5z"
            fill="url(#prefix__p)"
          />
          <path
            d="M37.727 156.5H28.55v-1.47a3.595 3.595 0 013.595-3.593h2a3.595 3.595 0 013.595 3.595l-.013 1.468z"
            fill="url(#prefix__q)"
          />
          <path
            d="M56.398 156.5H47.22v-1.47a3.592 3.592 0 013.595-3.593h2a3.593 3.593 0 013.595 3.595l-.012 1.468z"
            fill="url(#prefix__r)"
          />
        </g>
        <path fill="#224071" fillRule="nonzero" d="M105.088 111.177h28.27v20.895h-28.27z" />
        <path
          d="M105.088 116.707s10.547 1.23 14.135 1.23c3.587 0 14.134-1.23 14.134-1.23v-5.224h-28.27v5.224z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M133.357 116.4s-10.547 1.23-14.135 1.23c-3.587 0-14.134-1.23-14.134-1.23V95.812c0-7.806 6.328-14.135 14.135-14.135 7.806 0 14.134 6.329 14.134 14.135V116.4z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <path
          d="M115.843 74.61h6.75v8.313a2.75 2.75 0 01-2.75 2.75h-1.25a2.75 2.75 0 01-2.75-2.75V74.61z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          fill="#224071"
          fillRule="nonzero"
          d="M105.088 124.698h10.14v68.83h-10.14zM123.218 124.698h10.14v68.83h-10.14z"
        />
        <path
          d="M115.843 79.733c2.217.544 4.532.544 6.75 0v-4.816h-6.75v4.816z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <circle fill="#814E4D" fillRule="nonzero" cx={119.222} cy={62.32} r={14.75} />
        <circle fill="#FDB797" fillRule="nonzero" cx={119.222} cy={65.7} r={14.135} />
        <path
          d="M110.657 58.612a6.482 6.482 0 002.5.75c3.62.495 7.308.081 10.728-1.205.56 1.42 1.927 2.396 3.38 2.858 1.453.462 3 .487 4.53.5.336.037.676-.028.975-.188.68-.46.35-1.5-.045-2.227l-1.97-3.57c-.582-1.055-1.18-2.13-2.082-2.93-1.13-1-2.63-1.468-4.11-1.765a23.175 23.175 0 00-10.548.352c-3.675 1.003-8.403 4.37-3.358 7.425z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M110.657 58.305a6.482 6.482 0 002.5.75c3.62.495 7.308.08 10.728-1.205.56 1.42 1.927 2.395 3.38 2.858 1.453.462 3 .487 4.53.5.336.037.676-.029.975-.188.68-.46.35-1.5-.045-2.228l-1.97-3.57c-.582-1.055-1.18-2.13-2.082-2.93-1.13-1-2.63-1.467-4.11-1.764a23.175 23.175 0 00-10.548.352c-3.675 1.005-8.403 4.37-3.358 7.425z"
          fill="#814E4D"
          fillRule="nonzero"
        />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={133.205} cy={65.7} rx={1.23} ry={2.305} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={105.243} cy={65.7} rx={1.23} ry={2.305} />
        <circle fill="#FDB797" fillRule="nonzero" cx={157.018} cy={47.57} r={3.38} />
        <path
          d="M120.063 92c.75 0 1.453.074 2.109.223.656.148 1.226.386 1.71.715.485.328.868.757 1.15 1.289.28.53.421 1.18.421 1.945 0 1.11-.195 2.05-.586 2.824a5.234 5.234 0 01-1.57 1.887 6.465 6.465 0 01-2.285 1.055c-.867.218-1.778.328-2.73.328h-1.548l-.683 3.45-4.051 1.518 1.238-6.182c2.576.123 4.517-.13 5.821-.762 1.36-.659 2.272-1.809 2.736-3.45l-.036.116-.029.097.032-.097.029-.103.003-.013a2.59 2.59 0 00.073-.621c0-1.399-1.023-2.285-2.285-2.285a2.285 2.285 0 00-.898 4.386c.246.246.03.635-.647 1.168-.679.535-2.39 1.05-5.135 1.546l.351-.066L115.047 92z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M119.53 71.23s-8 2.765-14.135-3.38c0 0 .5 2.038 1.35 4.5a14.135 14.135 0 0026.43-4.392c-1.435.792-11.935 5.837-13.645 3.272zm-.308 3.688c-1.524 0-2.774-1.168-2.774-1.843s1.25-.615 2.765-.615c1.515 0 2.765-.065 2.765.615 0 .68-1.228 1.843-2.755 1.843z"
          fill="#814E4D"
          fillRule="nonzero"
        />
        <path
          d="M126.735 83.843s16.462-9.233 26.595-36.273l4.917 3.072S150.106 85.1 129.67 93.047l-2.935-9.204z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <circle fill="#FDB797" fillRule="nonzero" cx={81.427} cy={47.57} r={3.38} />
        <path
          d="M111.698 83.843S95.243 74.61 85.103 47.57l-4.905 3.072s8.145 34.458 28.577 42.405l2.922-9.204z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <path
          d="M109.297 191.067h1.723a3.593 3.593 0 013.593 3.593v1.322h-8.91v-1.322a3.592 3.592 0 013.594-3.593zM127.425 191.067h1.723a3.593 3.593 0 013.602 3.595v1.323h-8.918v-1.323a3.593 3.593 0 013.593-3.595z"
          fill="#333"
          fillRule="nonzero"
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={87.308} cy={118.885} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={87.308} cy={118.885} r={1} />
        <path
          d="M86.64 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={90.2} cy={118.885} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={90.2} cy={118.885} r={1} />
        <path
          d="M89.532 119.145a.89.89 0 011.5-.638.89.89 0 10-1.424 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={81.97} cy={118.885} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={81.97} cy={118.885} r={1} />
        <path
          d="M81.302 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={85.75} cy={116.365} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={85.75} cy={116.365} r={1} />
        <path
          d="M85.085 116.625a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={91.645} cy={116.772} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={91.645} cy={116.772} r={1} />
        <path
          d="M90.978 117.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={90.2} cy={115.882} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={90.2} cy={115.882} r={1} />
        <path
          d="M89.532 116.142a.89.89 0 011.5-.637.89.89 0 10-1.424 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={90.903} cy={117.995} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={90.903} cy={117.995} r={1} />
        <path
          d="M90.235 118.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={88.308} cy={116.365} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={88.308} cy={116.365} r={1} />
        <path
          d="M87.642 116.625a.89.89 0 011.5-.638.89.89 0 10-1.424 1.016.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={88.198} cy={113.547} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={88.198} cy={113.547} r={1} />
        <path
          d="M87.53 113.808a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={93.127} cy={114.995} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={93.127} cy={114.995} r={1} />
        <path
          d="M92.46 115.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={92.645} cy={116.885} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={92.645} cy={116.885} r={1} />
        <path
          d="M91.978 117.142a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={94.87} cy={116.513} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={94.87} cy={116.513} r={1} />
        <path
          d="M94.198 116.772a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={93.868} cy={117.44} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={93.868} cy={117.44} r={1} />
        <path
          d="M93.198 117.7a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={87.198} cy={114.995} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={87.198} cy={114.995} r={1} />
        <path
          d="M86.53 115.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={85.75} cy={118.775} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={85.75} cy={118.775} r={1} />
        <path
          d="M85.085 119.035a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={83.49} cy={118.885} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={83.49} cy={118.885} r={1} />
        <path
          d="M82.823 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={84.64} cy={118.255} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={84.64} cy={118.255} r={1} />
        <path
          d="M83.972 118.515a.89.89 0 011.5-.638.89.89 0 10-1.424 1.016.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={89.532} cy={117.142} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={89.532} cy={117.142} r={1} />
        <path
          d="M88.865 117.403a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={89.532} cy={114.772} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={89.532} cy={114.772} r={1} />
        <path
          d="M88.865 115.03a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={90.642} cy={116.662} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={90.642} cy={116.662} r={1} />
        <path
          d="M89.978 116.92a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={89.642} cy={112.88} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={89.642} cy={112.88} r={1} />
        <path
          d="M88.975 113.14a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={89.865} cy={113.882} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={89.865} cy={113.882} r={1} />
        <path
          d="M89.198 114.142a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={91.052} cy={114.55} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={91.052} cy={114.55} r={1} />
        <path
          d="M90.385 114.808a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={92.275} cy={114.362} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={92.275} cy={114.362} r={1} />
        <path
          d="M91.608 114.623a.89.89 0 011.5-.638.89.89 0 10-1.41 1.015.885.885 0 01-.09-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={91.94} cy={115.365} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={91.94} cy={115.365} r={1} />
        <path
          d="M91.275 115.625a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={87.752} cy={114.067} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={87.752} cy={114.067} r={1} />
        <path
          d="M87.085 114.328a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={84.862} cy={117.108} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={84.862} cy={117.108} r={1} />
        <path
          d="M84.198 117.365a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={86.603} cy={115.438} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={86.603} cy={115.438} r={1} />
        <path
          d="M85.948 115.698a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={83.045} cy={118.293} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={83.045} cy={118.293} r={1} />
        <path
          d="M82.377 118.552a.89.89 0 011.5-.637.89.89 0 10-1.424 1.015.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={92.645} cy={117.515} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={92.645} cy={117.515} r={1} />
        <path
          d="M91.978 117.772a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={88.16} cy={111.88} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={88.16} cy={111.88} r={1} />
        <path
          d="M87.493 112.14a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={86.345} cy={114.177} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={86.345} cy={114.177} r={1} />
        <path
          d="M85.677 114.438a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={84.897} cy={115.882} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={84.897} cy={115.882} r={1} />
        <path
          d="M84.233 116.142a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={85.938} cy={117.772} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={85.938} cy={117.772} r={1} />
        <path
          d="M85.27 118.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={87.345} cy={116.513} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={87.345} cy={116.513} r={1} />
        <path
          d="M86.677 116.772a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={88.085} cy={117.772} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={88.085} cy={117.772} r={1} />
        <path
          d="M87.42 118.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={95.757} cy={118.885} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={95.757} cy={118.885} r={1} />
        <path
          d="M95.093 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={94.35} cy={118.662} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={94.35} cy={118.662} r={1} />
        <path
          d="M93.683 118.922a.89.89 0 011.5-.637.89.89 0 10-1.426 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={91.645} cy={118.775} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={91.645} cy={118.775} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={91.83} cy={117.737} r={1} />
        <path
          d="M90.978 119.035a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={88.642} cy={118.885} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={88.642} cy={118.885} r={1} />
        <path
          d="M87.975 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={88.345} cy={114.882} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={88.345} cy={114.882} r={1} />
        <path
          d="M87.677 115.142a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={89.347} cy={116.142} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={89.347} cy={116.142} r={1} />
        <path
          d="M88.68 116.403a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={87.677} cy={113.067} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={87.677} cy={113.067} r={1} />
        <path
          d="M87.013 113.325a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={88.79} cy={113.177} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={88.79} cy={113.177} r={1} />
        <path
          d="M88.123 113.438a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={90.642} cy={113.698} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={90.642} cy={113.698} r={1} />
        <path
          d="M89.978 113.955a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={85.64} cy={115.328} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={85.64} cy={115.328} r={1} />
        <path
          d="M84.972 115.588a.89.89 0 011.5-.638.89.89 0 10-1.424 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={86.345} cy={116.625} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={86.345} cy={116.625} r={1} />
        <path
          d="M85.677 116.885a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={86.825} cy={118.145} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={86.825} cy={118.145} r={1} />
        <path
          d="M86.16 118.405a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={89.642} cy={117.662} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={89.642} cy={117.662} r={1} />
        <path
          d="M88.975 117.922a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={92.535} cy={116.772} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={92.535} cy={116.772} r={1} />
        <path
          d="M91.868 117.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={93.46} cy={115.995} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={93.46} cy={115.995} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={91.2} cy={115.92} r={1} />
        <path
          d="M92.793 116.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={94.905} cy={117.772} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={94.905} cy={117.772} r={1} />
        <path
          d="M94.24 118.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={90.235} cy={118.998} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={90.235} cy={118.998} r={1} />
        <path
          d="M89.57 119.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={85.085} cy={118.552} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={85.085} cy={118.552} r={1} />
        <path
          d="M84.418 118.813a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={83.638} cy={117.255} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={83.638} cy={117.255} r={1} />
        <path
          d="M82.972 117.515a.89.89 0 011.5-.638.89.89 0 10-1.424 1.016.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={85.528} cy={116.625} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={85.528} cy={116.625} r={1} />
        <path
          d="M84.862 116.885a.89.89 0 011.5-.638.89.89 0 10-1.414 1.003.885.885 0 01-.085-.365z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={84.49} cy={115.105} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={84.49} cy={115.105} r={1} />
        <path
          d="M83.825 115.365a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={85.38} cy={113.698} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={85.38} cy={113.698} r={1} />
        <path
          d="M84.713 113.955a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={93.127} cy={118.885} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={93.127} cy={118.885} r={1} />
        <path
          d="M92.46 119.145a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <path fill="url(#prefix__v)" fillRule="nonzero" d="M94.51 135.417H83.44l-2.805-16.532h16.718z" />
        <path fill="#FFF" fillRule="nonzero" d="M94.35 135.195H83.675l-2.705-16.05h16.123z" />
        <path
          fill="#286EFB"
          fillRule="nonzero"
          d="M80.97 119.145h2.743l2.595 16.05h-2.67zM97.093 119.145h-2.78l-2.668 16.05h2.705zM87.642 119.145h2.667v16.05h-2.667z"
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={83.972} cy={116.142} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={83.972} cy={116.142} r={1} />
        <path
          d="M83.305 116.403a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={86.493} cy={113.325} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={86.493} cy={113.325} r={1} />
        <path
          d="M85.825 113.585a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={82.97} cy={117.662} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={82.97} cy={117.662} r={1} />
        <path
          d="M82.305 117.922a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={81.772} cy={110.865} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={81.772} cy={110.865} r={1} />
        <path
          d="M81.105 111.123a.89.89 0 011.5-.638.89.89 0 10-1.407 1.015.885.885 0 01-.093-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={85.983} cy={105.13} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={85.983} cy={105.13} r={1} />
        <path
          d="M85.315 105.388a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={89.612} cy={100.772} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={89.612} cy={100.772} r={1} />
        <path
          d="M88.948 101.032a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__s)" fillRule="nonzero" cx={96.655} cy={110.138} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={96.655} cy={110.138} r={1} />
        <path
          d="M95.987 110.397a.89.89 0 011.5-.637.89.89 0 10-1.424 1.015.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={102.245} cy={117.252} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={102.245} cy={117.252} r={1} />
        <path
          d="M101.578 117.5a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.076-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={81.483} cy={134.602} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={81.483} cy={134.602} r={1} />
        <path
          d="M80.815 134.863a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={80.103} cy={132.787} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={80.103} cy={132.787} r={1} />
        <path
          d="M79.448 133.048a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={79.23} cy={122.987} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={79.23} cy={122.987} r={1} />
        <path
          d="M78.565 123.25a.89.89 0 011.5-.638.89.89 0 10-1.425 1.016.885.885 0 01-.075-.378z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={98.105} cy={133.805} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={98.105} cy={133.805} r={1} />
        <path
          d="M97.448 134.063a.89.89 0 011.5-.638.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__t)" fillRule="nonzero" cx={99.92} cy={132.643} r={1} />
        <circle fill="#FBC02D" fillRule="nonzero" cx={99.92} cy={132.643} r={1} />
        <path
          d="M99.252 132.903a.89.89 0 011.5-.638.89.89 0 10-1.424 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={100.138} cy={134.023} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={100.138} cy={134.023} r={1} />
        <path
          d="M99.472 134.282a.89.89 0 011.5-.637.89.89 0 10-1.424 1.015.885.885 0 01-.076-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <circle fill="url(#prefix__u)" fillRule="nonzero" cx={96.362} cy={134.023} r={1} />
        <circle fill="#FDD835" fillRule="nonzero" cx={96.362} cy={134.023} r={1} />
        <path
          d="M95.698 134.282a.89.89 0 011.5-.637.89.89 0 10-1.425 1.015.885.885 0 01-.075-.377z"
          fill="#FFF"
          fillRule="nonzero"
          opacity={0.2}
        />
        <text fill="#000" fillRule="nonzero" opacity={0.7} fontFamily="Lato-Regular, Lato" fontSize={9.953}>
          <tspan x={166.215} y={118.705}>
            {'9'}
          </tspan>
        </text>
        <path
          d="M60.608 41.807c-5.428-4.665-11.323-8.732-15.858-11.632.218-.7.385-1.416.5-2.14h.038c.732 0 1.327-1.115 1.327-2.5a3.455 3.455 0 00-.568-2.035 15.955 15.955 0 00-.42-5.578 5.56 5.56 0 00.278-1.467c-.008-.403.04-.805.14-1.195.154-.365.354-.708.595-1.022a6.465 6.465 0 00.578-5.858c-.268-.663-.7-1.325-1.378-1.558a1.46 1.46 0 01-.5-.205.89.89 0 01-.25-.66c-.038-.827.197-1.642.225-2.472.028-.83-.225-1.75-.942-2.165a2.705 2.705 0 00-1.41-.25 13.297 13.297 0 00-3.068.425c-1.575.41-3.338 1.087-4.728.25a11.52 11.52 0 01-1.07-.863c-1.817-1.427-4.78-1.042-6.172.803-.695.922-1.055 2.15-2.033 2.765-1.787 1.125-4.485-.5-6.13.84-1.235 1-1.012 3-2 4.25-.987 1.25-2.312 1.798-2.437 3.63-.075.673.103 1.35.5 1.898a15.86 15.86 0 00-1.547 6.854c0 .539.026 1.071.08 1.598a3.455 3.455 0 00-.578 2.053c0 1.375.595 2.5 1.328 2.5h.037c.125.762.308 1.515.548 2.25-4.5 2.892-10.323 6.914-15.683 11.52 1.66 9.952 14.93 16.25 14.93 16.25V78l-2.242.405a5.5 5.5 0 00-4.396 6.412l8.183 43.838a1.665 1.665 0 001.942 1.33l1.486-.278v.793c-1.568.75-2.655 1.105-2.655 2.955v.677h9.622v-.677c0-1.715-.935-1.983-2.322-2.782v-1.838l1.36-.25a1.665 1.665 0 001.33-1.943l-7.655-41.04h21.222l-7.662 41.045a1.665 1.665 0 001.33 1.943l1.36.25v1.838c-1.388.8-2.323 1.065-2.323 2.782v.677h9.622v-.677c0-1.85-1.087-2.213-2.655-2.955v-.797l1.485.292a1.665 1.665 0 001.943-1.33l8.282-44.392a4.947 4.947 0 00-3.955-5.778l-2.777-.5V58.075v-.483l.225.483s13.258-6.325 14.918-16.268zm-48.32 2l8.732-6.057a15.198 15.198 0 005.535 2.63v2.87a15.212 15.212 0 00-6.21 3.175l-8.057-2.617zm21.564-.557v-2.875a15.198 15.198 0 005.633-2.708l8.845 6.138-8.055 2.623.142.307a15.222 15.222 0 00-6.565-3.485z"
          opacity={0.7}
          transform="translate(138.5 55.25)"
          fill="url(#prefix__w)"
          fillRule="nonzero"
        />
        <path
          fill="#FDB797"
          fillRule="nonzero"
          d="M158.898 178.947h4.43v6.013h-4.43zM174.088 178.947h4.43v6.013h-4.43z"
        />
        <path fill="#4D8AF0" fillRule="nonzero" d="M154.153 129.58h29.113v10.443h-29.113z" />
        <path d="M154.152 129.58h29.113v10.443h-29.113z" opacity={0.05} fill="#000" fillRule="nonzero" />
        <path
          d="M154.153 131.48s10.862 2.52 14.544 2.52c3.683 0 14.558-2.532 14.558-2.532v-1.583h-29.102v1.595z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M183.265 131.162s-10.862 2.533-14.567 2.533c-3.706 0-14.558-2.532-14.558-2.532V113.75c.006-8.036 6.522-14.548 14.558-14.548a14.558 14.558 0 0114.567 14.548v17.412z"
          fill="#31D574"
          fillRule="nonzero"
        />
        <path
          d="M165.227 91.922h6.963v8.643a2.75 2.75 0 01-2.75 2.75h-1.463a2.75 2.75 0 01-2.75-2.75v-8.642z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          d="M164.917 180.97l-7.72 1.442a1.292 1.292 0 01-1.5-1.032l-8.052-43.065a4.265 4.265 0 013.41-4.977l4.802-.898a1.292 1.292 0 011.5 1.032l8.585 46a1.292 1.292 0 01-1.025 1.498zM172.5 180.97l7.725 1.442a1.292 1.292 0 001.5-1.032l8.118-43.482a3.84 3.84 0 00-3.07-4.478l-5.223-.975a1.292 1.292 0 00-1.5 1.032l-8.585 46a1.292 1.292 0 001.035 1.493z"
          fill="#4D8AF0"
          fillRule="nonzero"
        />
        <path
          d="M165.227 97.198c2.287.562 4.676.562 6.963 0V92.25h-6.963v4.948z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <circle fill="#FFCF51" fillRule="nonzero" cx={168.708} cy={79.265} r={15.19} />
        <circle fill="#FDB797" fillRule="nonzero" cx={168.708} cy={82.748} r={14.557} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={183.107} cy={82.748} rx={1.265} ry={2.373} />
        <ellipse fill="#FDB797" fillRule="nonzero" cx={154.31} cy={82.748} rx={1.265} ry={2.373} />
        <path
          d="M159.96 182.428h2a3.595 3.595 0 013.595 3.595v1.477h-9.188v-1.47a3.593 3.593 0 013.593-3.602zM175.465 182.428h2a3.595 3.595 0 013.595 3.595v1.477h-9.188v-1.47a3.593 3.593 0 013.593-3.602z"
          fill="#333"
          fillRule="nonzero"
        />
        <path
          d="M170.063 110c.75 0 1.453.074 2.109.223.656.148 1.226.386 1.71.715.485.328.868.757 1.15 1.289.28.53.421 1.18.421 1.945 0 1.11-.195 2.05-.586 2.824a5.234 5.234 0 01-1.57 1.887 6.465 6.465 0 01-2.285 1.055c-.867.218-1.778.328-2.73.328h-1.548l-.683 3.45-4.051 1.518 1.238-6.182c2.576.123 4.517-.13 5.821-.762 1.36-.659 2.272-1.809 2.736-3.45l-.036.116-.029.097.032-.097.029-.103.003-.013a2.59 2.59 0 00.073-.621c0-1.399-1.023-2.285-2.285-2.285a2.285 2.285 0 00-.898 4.386c.246.246.03.635-.647 1.168-.679.535-2.39 1.05-5.135 1.546l.351-.066 1.794-8.968z"
          fill="#FFF"
          fillRule="nonzero"
        />
        <path
          d="M155.417 74.835s-.632 4.115 2.848 1.898c3.48-2.218 15.5-1.266 19.303 1.897 3.802 3.163 5.062.633 5.694 0 .633-.632-6.85-11.203-11.075-12.025-11.39-2.213-16.77 8.23-16.77 8.23z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M155.417 74.52s-.632 4.115 2.848 1.898c3.48-2.218 15.5-1.266 19.303 1.897 3.802 3.163 5.062.633 5.694 0 .633-.632-6.85-11.203-11.075-12.025-11.39-2.215-16.77 8.23-16.77 8.23z"
          fill="#FFCF51"
          fillRule="nonzero"
        />
        <path
          d="M156.847 67.457c.953-1.177.75-3.097 1.918-4.047 1.568-1.265 4.14.273 5.845-.8.932-.587 1.275-1.75 1.938-2.638 1.327-1.75 4.152-2.127 5.887-.767a11 11 0 001.02.823c1.325.807 3 .16 4.5-.23a12.682 12.682 0 012.925-.406 2.58 2.58 0 011.345.25c.685.393.925 1.278.9 2.066-.025.787-.25 1.567-.215 2.357-.013.233.07.46.23.63.143.093.301.16.468.195.644.22 1.06.853 1.315 1.485a6.165 6.165 0 01-.55 5.588c-.23.3-.42.627-.568.975a4.233 4.233 0 00-.135 1.14c-.072 1.347-.645 2.802-1.87 3.367-1.578.73-3.355-.28-4.913-1.055-3.535-1.75-7.52-2.365-11.447-2.778a45.517 45.517 0 00-5.303-.314c-1.317.014-3.1.58-4.355.044-1-.427-1.345-1.382-1.272-2.417.133-1.752 1.365-2.263 2.338-3.468z"
          fill="#FFCF51"
          fillRule="nonzero"
        />
        <path
          d="M159.303 102.653l-7.683-2.5 15.5-10.75-4.75-6.646s-12.123 6.613-22.468 15.5c1.583 9.5 14.25 15.5 14.25 15.5l5.15-11.105zM178.315 102.653l7.683-2.5-15.5-10.75 4.75-6.646s12.122 6.613 22.467 15.5c-1.583 9.5-14.25 15.5-14.25 15.5l-5.15-11.105z"
          fill="#31D574"
          fillRule="nonzero"
        />
        <path
          d="M162.697 83.063s7.91-5.695 9.81-2.216c1.9 3.48-5.06 8.86-5.06 8.86l-4.75-6.644z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M162.38 82.75s7.91-5.695 9.81-2.215c1.9 3.48-5.063 8.86-5.063 8.86l-4.747-6.645z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          d="M162.38 82.75s7.91-5.695 9.81-2.215c1.9 3.48-5.063 8.86-5.063 8.86l-4.747-6.645z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.02}
        />
        <path
          d="M174.72 83.063s-7.91-5.695-9.81-2.216c-1.9 3.48 5.063 8.86 5.063 8.86l4.747-6.644z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.1}
        />
        <path
          d="M175.037 82.75s-7.91-5.695-9.81-2.215c-1.9 3.48 5.063 8.86 5.063 8.86l4.748-6.645z"
          fill="#FDB797"
          fillRule="nonzero"
        />
        <path
          d="M175.037 82.75s-7.91-5.695-9.81-2.215c-1.9 3.48 5.063 8.86 5.063 8.86l4.748-6.645z"
          fill="#000"
          fillRule="nonzero"
          opacity={0.02}
        />
        <path
          d="M7.992 1.68L7.365.18.713 2.912a.822.822 0 00-.5.75v7.188l1.922.223V4.216L7.992 1.68z"
          transform="translate(144.5 104.5)"
          fill="url(#prefix__x)"
          fillRule="nonzero"
        />
        <path
          d="M152.175 106.15l-.478-1.135-6.225 2.558a.768.768 0 00-.477.71V115h1.23v-6.41l5.95-2.44z"
          fill="#F2F2F2"
          fillRule="nonzero"
        />
        <g transform="translate(185.25 118.5)">
          <mask id="prefix__z" fill="#fff">
            <use xlinkHref="#prefix__y" />
          </mask>
          <g mask="url(#prefix__z)" fill="#286EFB" fillRule="nonzero">
            <path d="M-35.218-14.722h1.34v4.247h-1.34zM-37.34-13.828H-36v4.247h-1.34zM-38.065-10.197l-.95.28-.393-4.526h1.343z" />
            <path d="M-41.532-8.352v-1.34h4.247v1.34zM-41.53-6.508v-1.34h4.247v1.34zM-41.53-4.665v-1.34h4.247v1.34z" />
          </g>
        </g>
        <path
          fill="url(#prefix__A)"
          fillRule="nonzero"
          d="M153.33 113.835h-14.918v2.353h1.018l2.075 19.047h8.915l1.843-19.158h1.067z"
        />
        <path fill="#286EFB" fillRule="nonzero" d="M150.032 134.845h-8.212l-2.012-20.115h12.014z" />
        <path fill="#286EFB" fillRule="nonzero" d="M138.97 114.395h13.745v1.34H138.97z" />
        <circle fill="url(#prefix__B)" fillRule="nonzero" cx={145.843} cy={123.67} r={3.408} />
        <circle fill="#FFF" fillRule="nonzero" cx={145.843} cy={123.67} r={3.13} />
        <path
          d="M36.998 26.58a3.345 3.345 0 00-3.05-.08l.13-.25a3.352 3.352 0 00-1.348-4.547 3.352 3.352 0 00-4.532 1.364l-1.143 2.105 2.36-4.35a3.032 3.032 0 00-1.22-4.112l-.563-.305a3.032 3.032 0 00-4.112 1.22l5.535-10.197A4.843 4.843 0 0027.108.86l-.375-.203A3.352 3.352 0 0022.177 2L13.57 17.858.165 42.555l13.05 7.083a13.25 13.25 0 0017.983-5.323l4.797-8.84 2.36-4.35a3.352 3.352 0 00-1.357-4.545z"
          opacity={0.7}
          transform="translate(144.25)"
          fill="url(#prefix__C)"
          fillRule="nonzero"
        />
        <path
          d="M159.789 49.877l-14.647-7.949 12.894-23.757 23.774 12.903-7.945 14.638a10.375 10.375 0 01-14.076 4.165z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <path
          d="M179.616 35.112l-5.671-3.078 2.559-4.715a2.625 2.625 0 013.559-1.055l1.057.574a2.625 2.625 0 011.055 3.559l-2.56 4.715z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <path
          d="M175.503 30.436l-5.671-3.077 2.558-4.714a2.625 2.625 0 013.56-1.055l1.056.574a2.625 2.625 0 011.055 3.56l-2.558 4.712z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <path
          d="M170.863 25.47l-5.671-3.077 2.678-4.935a2.375 2.375 0 013.22-.955l1.497.812a2.375 2.375 0 01.954 3.22l-2.678 4.935zM165.188 22.39l-7.293-3.959 8.715-16.057a2.625 2.625 0 013.56-1.055l1.652.897a3.792 3.792 0 011.524 5.142l-8.158 15.031z"
          fill="#286EFB"
          fillRule="nonzero"
        />
        <rect
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#286EFB"
          fillRule="nonzero"
          transform="rotate(-151.51 175.673 35.597)"
          x={173.829}
          y={27.915}
          width={3.688}
          height={15.365}
          rx={1.5}
        />
        <rect
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#286EFB"
          fillRule="nonzero"
          transform="rotate(-151.51 171.715 30.652)"
          x={169.871}
          y={22.97}
          width={3.688}
          height={15.365}
          rx={1.5}
        />
        <rect
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#286EFB"
          fillRule="nonzero"
          transform="rotate(-151.51 167.365 25.141)"
          x={165.522}
          y={17.459}
          width={3.688}
          height={15.365}
          rx={1.5}
        />
        <rect
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#286EFB"
          fillRule="nonzero"
          transform="rotate(-151.51 165.866 12.44)"
          x={164.022}
          y={4.758}
          width={3.688}
          height={15.365}
          rx={1.5}
        />
        <path
          d="M155.923 42.355s4.774-3-.118-8.802l9.797-5.17s2.076-4.468-4.422-3.448c-6.498 1.02-6.125 2.27-6.125 2.27"
          stroke="#FFF"
          strokeWidth={0.5}
          fill="#286EFB"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
