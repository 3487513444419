import React, { ReactComponentElement, ReactElement } from 'react';
import { isAfter, isBefore } from 'date-fns';
import gql from 'graphql-tag';
import clsx from 'clsx';

import { GameDateTime } from '../GameDateTime';
import { Score } from '../Score';
import { FlagsCode, TeamWithFlag } from '../TeamWithFlag';
import { PronostixButton, PronostixButtonStatus } from '../PronostixButton';
import { GameLineDataFragment } from '../../generated/graphql';
import { Button } from '../Button';
import { ForecastPanel } from '../ForecastPanel';

export interface GameLineProps {
  game: GameLineDataFragment;
  className?: string;
  variant?: 'group' | 'default';
  groupButton?: ReactElement;
}

const fragments = {
  GameLineData: gql`
    fragment GameLineData on match {
      id
      awayScore
      homeScore
      teamAway {
        id
        playerTeam {
          id
          intlKey
        }
      }
      teamHome {
        id
        playerTeam {
          id
          intlKey
        }
      }
      playedAt
      isMatchOver
      forecasts(where: { profileId: { _eq: $profileId } }) {
        matchId
        profileId
        estimatedAway
        estimatedHome
        forecastsStatus {
          value
        }
      }
    }
  `,
};

const statusMapper: { [key: string]: PronostixButtonStatus } = {
  miss: 'lose',
  partial: 'partialSuccess',
  perfect: 'success',
};

export const GameLine = ({ game, className, groupButton }: GameLineProps) => {
  const date = new Date(game.playedAt);
  const now = new Date();
  const forecast = game.forecasts?.[0];
  let forecastStatus: PronostixButtonStatus = 'missed';
  const isMatchStarted = isAfter(now, date);
  if (forecast) {
    if (isMatchStarted && !game.isMatchOver) {
      forecastStatus = 'inProgress';
    } else if (isBefore(now, date)) {
      forecastStatus = 'planned';
    } else if (forecast.forecastsStatus?.value) {
      forecastStatus = statusMapper[forecast.forecastsStatus.value];
    }
  }

  let defaultClassName = 'flex items-center justify-between py-4 md:flex-row flex-col';

  let classNames = clsx(defaultClassName, className);

  const isTeamUnknown = !game.teamAway || !game.teamHome;
  return (
    <div className={classNames}>
      <GameDateTime className="md:w-3/12" date={date} />
      <div className="flex md:w-9/12 w-full md:my-0 my-4">
        <TeamWithFlag
          teamCode={(game.teamHome?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
          variant="bold"
          className="w-5/12 inline-flex justify-end ml-2"
        />
        <div className="w-2/12 inline-flex justify-center">
          <Score scoreHomeTeam={game.homeScore} scoreAwayTeam={game.awayScore} />
        </div>
        <TeamWithFlag
          teamCode={(game.teamAway?.playerTeam.intlKey as FlagsCode) ?? 'UNKNOWN'}
          reverse={true}
          variant="bold"
          className="w-5/12 inline-flex justify-end mr-2"
        />
      </div>

      <div className="text-right md:w-2/12">
        {groupButton ? (
          groupButton
        ) : (
          <ForecastPanel.TriggerForecastPanel matchId={game.id}>
            {game.forecasts.length === 0 && !isMatchStarted ? (
              <Button disabled={isTeamUnknown}>Pronostiquer</Button>
            ) : (
              <PronostixButton
                scoreHomeTeam={forecast?.estimatedHome}
                scoreAwayTeam={forecast?.estimatedAway}
                disabled={isMatchStarted && !game.isMatchOver}
                variant={forecastStatus}
              />
            )}
          </ForecastPanel.TriggerForecastPanel>
        )}
      </div>
    </div>
  );
};

GameLine.fragments = fragments;
